import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';

function Contact() {
    return (
        <>
            <section className='home-banner'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <img src="./assets/image1.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-pending faq-1">
                <div className="container">
                    <div className="row faq-1-1">
                        <div className="col-md-12">
                            <h2 className='title'>Contact Us</h2>
                        </div>
                    </div>
                    <div className="row tcnt-3 mt-5">
                        <div className='col-md-3'>
                            <h3>Address</h3>
                            <p>Plot No. T-19,1st & 2nd,Floor,<br />
                                Block_IV Dhanwantari Bhawan,<br />
                                Road No 66,Punjabi Bagh(West),<br />
                                New Delhi-110026 Delhi,India
                            </p>
                        </div>
                        <div className='col-md-3'>
                            <h3>Phone</h3>
                            <p>011-42424450</p>
                        </div>
                        <div className='col-md-3'>
                            <h3>Email Address</h3>
                            <p>dpp.helpdesk@ncismindia.org</p>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
}

export default Contact;
