import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function Home() {
    return (
        <>
            <section className='home-banner'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <img src="./assets/image1.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className='section-pending home-intro'>
                <div className="container">
                    <div className='row'>
                        <div className='col-md-9'>
                            <div className="row home-intro-1">
                                <div className="col-md-6">
                                    <img src="./assets/DoctorGraphic.svg" alt="" />
                                </div>
                                <div className="col-md-6">
                                    <h2 className='title'>Desh ka Prakriti Parikshan</h2>
                                    <p>The Desh ka Prakriti Parikshan initiative, under the Ministry of AYUSH, aims to bring Ayurveda to every individual’s doorstep by helping people know about  their unique Prakriti, or Ayurvedic body type. With the vision of our Honourable Prime Minister Shri Narendra Modi and the guidance from the Minister of State for AYUSH, Shri Prataprao Jadhav, this national program is managed by the National Commission for Indian System of Medicine (NCISM) and aims to inspire individuals across India to incorporate Ayurveda-based lifestyle practices. Participants will pledge to adopt a healthier lifestyle and have an opportunity to be part of setting several Guinness World Records, including the largest online photo album of individuals with Prakriti certificates, the most pledges for a health campaign, and the largest video album of participants sharing a campaign message. This campaign is your chance to understand your Prakriti, adopt personalized health practices, and join a nationwide movement for wellness.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className='title'>What is Prakriti?</h2>
                                    <p>Prakriti, in Ayurveda, is your unique mind-body constitution influenced by the three Doshas: Vata, Pitta, and Kapha. This Ayurvedic classification recognizes individual differences, meaning each person’s Prakriti influences their physical, mental, and emotional characteristics along with their response to environment and stress. Understanding your Prakriti helps you make lifestyle choices suited to your natural state, enabling you to live in balance and harmony with your own biological rhythms and that of environment. Prakriti awareness is a cornerstone of Ayurveda’s approach to personalized and predictive health and wellness, paving the way for a preventive and holistic lifestyle through what is known as P5 medicine (predictive, preventive, personalized, participatory, and precision medicine).</p>
                                </div>
                                <div className="col-md-6">
                                    <video controls>
                                        <source src="./assets/PrakritiVideo.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <img src="./assets/HolisticHealthAndWellness.jpeg" alt="" />
                                </div>
                                <div className="col-md-6">
                                    <h2 className='title'>Holistic Health and Wellness</h2>
                                    <p>This campaign highlights Ayurveda's time-tested practices for maintaining balance, promoting wellness, and strengthening the body’s natural resilience. Based on your Prakriti, you’ll receive lifestyle recommendations that include daily routines, dietary advice, exercise , yoga and seasonal adjustments. These guidelines can help improve overall health and prevent imbalances before they become health issues. Ayurveda emphasizes the balance of mind, body, and environment to sustain wellness. By understanding your Prakriti, you can adopt practices that fit your unique nature and improve your quality of life.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="right-box">
                                {/* <div className='user-box'>
                                    <img src="https://youthparliamentofindia.co.in/wp-content/uploads/2021/10/J.-P.jpg" alt="" />
                                    <h3>Shri Jagat Prakash Nadda</h3>
                                    <p>Minister</p>
                                </div> */}
                                <div className='user-box'>
                                    <img src="https://www.yogamdniy.nic.in/ckfinder/userfiles/files/Prataprao%20Jadhav.jpeg" alt="" />
                                    <h3>Shri Jadhav Prataprao Ganpatrao</h3>
                                    <p>Minister of State (Independent Charge) Ministry of AYUSH & Ministry of Health and Family Welfare</p>
                                </div>
                                {/* <div className='user-box'>
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/The_official_portrait_of_State_Minister_Anupriya_Patel.jpg/640px-The_official_portrait_of_State_Minister_Anupriya_Patel.jpg" alt="" />
                                    <h3>Smt. Anupriya Patel</h3>
                                    <p>Minister of State</p>
                                </div> */}
                                <div className='research-box'>
                                    <h3>Science and Research Behind Prakriti</h3>
                                    <p>Here is a list of curated articles and studies related to Ayurgenomics and Prakriti. We encourage you to delve deeper and expand your knowledge base. </p>
                                    <a href="/sience-corner" className='btn'>Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;
