import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import FaqData from '../../data/faq.json';

function Faq() {
    return (
        <>
            <section className='home-banner'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <img src="./assets/image1.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-pending faq-1">
                <div className="container">
                    <div className="row faq-1-1">
                        <div className="col-md-12">
                            <h2 className='title'>FAQs</h2>
                        </div>
                    </div>
                    <Accordion defaultActiveKey="0" alwaysOpen>
                        <div className="row">
                            {
                                FaqData.map((v, i) => {
                                    return (
                                        <div className="col-md-8 faq-box">
                                            <Accordion.Item eventKey={i}>
                                                <Accordion.Header>{v?.title}</Accordion.Header>
                                                <Accordion.Body>{v?.description}</Accordion.Body>
                                            </Accordion.Item>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Accordion>
                </div>
            </section >
        </>
    );
}

export default Faq;
