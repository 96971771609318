import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function Abhiyaan() {
    return (
        <>
            <section className='home-banner'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <img src="./assets/image1.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className='ev-ab-1 section-pending'>
                <div className="container">
                    <div className="row ev-ab-1-1">
                        <div className="col-md-12">
                            <h2 className='title'>Join The Abhiyaan</h2>
                            <p>Be a part of this exciting initiative! Here’s how you can participate in the Desh ka Prakriti Parikshan campaign:</p>
                        </div>
                        <div className="col-md-12 ev-ab-1-1-2">
                            <a href="https://apps.apple.com/in/app/prakruti-parikshan/id6736818809" target='_blank'>
                                <img src="./assets/apple-store.png" alt="" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.artcode.prakrutiparikshan" target='_blank'>
                                <img src="./assets/google-store.png" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="row ev-ab-1-2">
                        <div className="col-md-4">
                            <div className="box">
                                <img src="./assets/undraw_app_installation_re_h36x.svg" alt="" />
                                <h3>Download the App</h3>
                                <p>Start by downloading the Prakriti Parikshan app and logging in with your mobile number and OTP verification. (Link for android and ios app.)</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box">
                                <img src="./assets/undraw_app_data_re_vg5c.svg" alt="" />
                                <h3>Fill in Your Details and Consent</h3>
                                <p>Once logged in, provide basic details like your name, age, and location, and approve your consent to share your information voluntarily.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box">
                                <img src="./assets/undraw_medicine_b-1-ol.svg" alt="" />
                                <h3>Pledge for Health and Wellness</h3>
                                <p>Take a simple pledge to embrace an Ayurveda-based lifestyle for better health.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row ev-ab-1-2">
                        <div className="col-md-4">
                            <div className="box">
                                <img src="./assets/undraw_workout_gcgu.svg" alt="" />
                                <h3>Prakriti Assessment with an<br />Ayurveda Volunteer</h3>
                                <p>After your details are in, you can locate or meet with an Ayurveda professional in your area who will assess your Prakriti by examining physical, physiological, and behavioural features. (Link to locate nearby hospital or ayurveda centre)</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box">
                                <img src="./assets/undraw_mobile_photos_psm5.svg" alt="" />
                                <h3>Capture the Moment</h3>
                                <p>Say the campaign sentence on video as part of the initiative, and then receive your Prakriti certificate.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box">
                                <img src="./assets/undraw_winners_re_wr1l.svg" alt="" />
                                <h3>Be a Part of World Records</h3>
                                <p>With your certificate and participation, you’ll join thousands of others in creating a world record, highlighting a nationwide commitment to Ayurveda.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Abhiyaan;
