import React from 'react';


function Footer() {
    return (
        <footer className="main-footer">
            <div className="container">
                <div className='logos'>
                    <img src="./assets/prakriti_logo.png" />
                    <img src="./assets/NCISM_logo.png" />
                    <img src="./assets/GMA_logo.png" />
                    <img src="./assets/IGIB_logo.png" />
                </div>
                <div className="row">
                    <div className='col-md-4 footer-col-1'>
                        <h3>About Us</h3>
                        <p>The Desh ka Prakriti Parikshan initiative, under the Ministry of AYUSH, aims to bring Ayurveda to every individual’s doorstep by helping people know about their unique Prakriti, or Ayurvedic body type.</p>
                        <div className='box'>
                            <a href="https://apps.apple.com/in/app/prakruti-parikshan/id6736818809" target='_blank'>
                                <img src="./assets/apple-store-white.jpg" alt="" />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.artcode.prakrutiparikshan" target='_blank'>
                                <img src="./assets/google-store-white.jpg" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className='col-md-2'></div>
                    <div className='col-md-2'>
                        <h3>Help Center</h3>
                        <ul>
                            <li><a href="/terms-condition">Terms & Conditions</a></li>
                            <li><a href="/privacy-policy">Privacy-Policy</a></li>
                            <li><a href="/contact-us">Contact Us</a></li>
                        </ul>
                    </div>
                    <div className='col-md-1'></div>
                    <div className="col-md-3 footer-contect">
                        <h3>Contact Us</h3>
                        <ul>
                            <li>
                                <a href="#">
                                    <label htmlFor="">Support :</label>
                                    011-42424450
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <label htmlFor="">Email Address :</label>
                                    dpp.helpdesk@ncismindia.org
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='copyright'>
                    <div className='row'>
                        <div className="col-md-6"><a href="">@2024 NCISM All rights reserved</a></div>
                        <div className="col-md-6"><a href="">Design & Develop by Artcode PVT LTD.</a></div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;