import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BrowserRouter, useLocation } from 'react-router-dom';

function Header() {
    let location = useLocation();
    const [menuActive, setMenuActive] = useState('/')

    useEffect(() => {
        setMenuActive(location.pathname);
    }, [location]);


    return (
        <header className='header-main'>
            <div className='top-header'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <ul>
                                <li><a href=""><img src="./assets/facebook.svg" alt="" /></a></li>
                                <li><a href=""><img src="./assets/insta.svg" alt="" /></a></li>
                                <li><a href=""><img src="./assets/x.svg" alt="" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='menu-header'>
                <div className="container">
                    <div className="row">
                        <div className="col-9 col-md-4 header-logo">
                            <a href="/">
                                <img src="./assets/prakriti_logo.png" />
                                <img src="./assets/GMA_logo.png" />
                            </a>
                            {/* <img src="./assets/NCISM_logo.png" /> */}
                            {/* <img src="./assets/GMA_logo.png" /> */}
                            {/* <img src="./assets/IGIB_logo.png" /> */}
                        </div>
                        <div className="col-3 col-md-8 header-menu">
                            <Navbar expand="md">
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav>
                                        <Nav.Link href="/" className={menuActive == '/' ? 'active' : ''}>Home</Nav.Link>
                                        <Nav.Link href="faqs" className={menuActive == '/faqs' ? 'active' : ''}>FAQs</Nav.Link>
                                        <Nav.Link href="volunteers-corner" className={menuActive == '/volunteers-corner' ? 'active' : ''}>Volunteers’ Corner</Nav.Link>
                                        <Nav.Link href="sience-corner" className={menuActive == '/sience-corner' ? 'active' : ''}>Science Corner</Nav.Link>
                                        <Nav.Link href="join-the-abhiyaan" className='jbtn'>Join the Abhiyaan</Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
