import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';

function PrivacyPolicy() {
    return (
        <>
            <section className='home-banner'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <img src="./assets/image1.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-pending faq-1">
                <div className="container">
                    <div className="row faq-1-1">
                        <div className="col-md-12">
                            <h2 className='title'>Privacy Policy</h2>
                        </div>
                        <div className="col-md-12 tcnt-2 mt-5">
                            <p>The Privacy Policy for the Desh Ka Prakriti Prikshan App, developed under guidelines of the
                                National Commission for Indian System of Medicine (NCISM) comply with the Digital Data
                                Protection Act, 2023 (DDPA). This ensures that all personal data collection, processing, and
                                storage align with the legal standards for user privacy and data security in the digital age.</p>
                            <h3>1. Privacy Policy Overview</h3>
                            <p>The Privacy Policy explains how user data is collected, stored, and used in compliance with the
                                DDPA 2023, emphasizing transparency, user control, and data protection.</p>
                            <p><strong>Data Collection:</strong></p>
                            <p>Personal Data: Includes name, email, demographic details (age, gender), and preferences that
                                the user provides during registration or app usage.</p>
                            <p>Sensitive Data: This includes location data (optional) needed to provide localized
                                environmental data and nature insights.</p>
                            <p>Children’s Data: Users below 18 are not permitted to use the app without parental consent,
                                ensuring compliance with child data protection guidelines.</p>
                            <p>Purpose Limitation: Data is collected and used only for the specific purposes stated during
                                collection, such as personalized nature content, environmental information, or app improvement.</p>
                            <p>Lawful Basis for Data Processing:</p>
                            <p>Personal data is processed based on explicit user consent, performance of a contract (app
                                functionality), or legitimate interest (e.g., analytics), ensuring compliance with DDPA.</p>
                            <p>Consent Management: Users are informed about the data being collected and are provided with
                                the ability to opt in or opt out at any time. Consent is granular, allowing users to choose which
                                data to share (e.g., opting in for location services but not marketing communications).</p>
                            <p>Withdrawal of Consent: Users can withdraw consent at any time without impacting the legality
                                of previous data processing.</p>
                            <p>Data Usage:</p>
                            <p>Personal and sensitive data are used for providing services like environmental insights, location-
                                based nature information, app functionality improvements, and personalized notifications (if
                                consented).</p>
                            <p>Non-personal data (e.g., device type, OS) is used for analytics to improve app performance and
                                user experience.</p>
                            <p>Data Sharing and Third-Party Involvement:</p>
                            <p>Data is shared with third-party service providers (e.g., cloud services, data analytics
                                platforms) only to the extent necessary to perform app functions.</p>
                            <p>No Sale of Personal Data: The app does not sell user data to advertisers or other third parties.
                                Any third-party sharing is subject to the same data protection standards under the DDPA.</p>
                            <p>Data Transfers: In cases where data is transferred outside the user’s jurisdiction (cross-border),
                                it is done in compliance with international data protection standards as per the Digital Data
                                Protection Act, 2023.</p>
                            <p>Data Retention:</p>
                            <p>Personal data is retained only as long as necessary for the purposes outlined or as required by
                                law. Once the data’s purpose is fulfilled, it is deleted or anonymized in compliance with the
                                Data Retention and Minimization guidelines of the DDPA.</p>
                            <p>User Rights under DDPA 2023:</p>
                            <p>Right to Access: Users can request access to their personal data collected by the app.</p>
                            <p>Right to Rectification: Users can correct inaccurate or incomplete personal data.</p>
                            <p>Right to Erasure (Right to be Forgotten): Users can request the deletion of their personal
                                data.</p>
                            <p>Right to Data Portability: Users can request the transfer of their data to another service
                                provider in a machine-readable format.</p>
                            <p>Right to Object: Users can object to specific data processing activities, including marketing or
                                profiling.</p>
                            <p>How We May Use and Share Your Health Information</p>
                            <p>- Research: We may use your information for research purposes with your consent or when the
                                data does not personally identify you.</p>
                            <p>- As Required By Law: We will disclose your information as required by law to comply with
                                legal obligations.</p>
                            <p>- Health Awareness:- we may use your data for informing/offering you health tips according to
                                your Prakruti from time to time. You may exit if you desire so.</p>
                            <p>Data Security Measures:</p>
                            <p>The app uses state-of-the-art encryption, secure storage, and access control mechanisms to
                                protect user data.</p>
                            <p>Data Breaches: In the event of a data breach, users and authorities will be notified promptly, as
                                required under the DDPA.</p>
                            <h3>2. Terms & Conditions Overview</h3>
                            <p>The Terms & Conditions (T&C) define the legal relationship between the app and its users,
                                ensuring full compliance with the Digital Data Protection Act, 2023.</p>
                            <p>User Responsibilities:</p>
                            <p>Users must provide accurate information when registering or using the app.</p>
                            <p>Users must comply with the community guidelines when interacting with others on the app (if
                                applicable), ensuring that any submitted data (e.g., images, environmental reports) is lawful and
                                respectful.</p>
                            <p>Permissions Required:</p>
                            <p>Location Access: The app requires explicit user consent to access the user’s location in order to
                                provide real-time environmental insights based on the user’s geographical area. Users can
                                choose to share their location only when using the app, or grant continuous access based on their
                                preference.</p>
                            <p>Camera and Photos Access: If users want to take photos of their surroundings for
                                environmental data or conservation purposes, the app will request camera and photo access.</p>
                            <p>Storage Access: This permission is requested to save and manage app data, reports, or images
                                on the user’s device.</p>
                            <p>Network Access: The app requires internet access for syncing data with servers, retrieving real-
                                time nature insights, and downloading app updates.</p>
                            <p>Lawful Processing of Permissions:</p>
                            <p>Permissions will be requested explicitly and separately from users, ensuring compliance
                                with purpose limitation and data minimization as outlined in the DDPA.</p>
                            <p>Users have the right to deny or revoke any permissions at any time without affecting the core
                                functionality of the app (where feasible).</p>
                            <p>Liability Disclaimer:</p>
                            <p>The app offers educational and informational content related to health care awarenessand -
                                related insights. While efforts are made to provide accurate data, users understand that
                                information may vary due to factors outside the app’s control (e.g., data source reliability, etc.).</p>
                            <p>The app is not liable for any loss or damage arising from misuse, incorrect interpretation of
                                data, or unauthorized access to a user’s account.</p>
                            <p>Termination of Service:</p>
                            <p>The app reserves the right to suspend or terminate user accounts in case of breaches of the
                                Terms & Conditions, misuse of the app, or any violation of applicable laws.</p>
                            <p>Amendments to T&C and Privacy Policy:</p>
                            <p>Users will be informed of any significant changes to the Privacy Policy or T&C. Continued
                                use of the app after updates implies acceptance of the new terms.</p>
                            <h3>3. Permissions Overview (In Accordance with DDPA 2023)</h3>
                            <p>Permissions Required by the app include: Location Access: Required for providing real-time
                                environmental and nature data based on the user’s location. The app will explicitly ask users to
                                enable this permission and will allow them to revoke or limit access (e.g., only while using the
                                app).</p>
                            <p>Camera Access: Requested if the app includes a feature that allows users to upload photos of
                                natural surroundings or contribute to environmental reporting.</p>
                            <p>Storage Access: Needed to store app data, reports, and images on the device. Users can manage
                                and revoke this permission in their device settings.</p>
                            <p>Network Access: Required for syncing data with servers, updating app content, and providing
                                real-time nature insights.</p>
                            <p>Transparency and User Control:</p>
                            <p>All permissions are requested through a clear, user-friendly consent flow, ensuring users are
                                fully aware of the reasons behind each permission.</p>
                            <p>Users have the option to opt out of certain permissions without losing access to the app’s core
                                features (where feasible).</p>
                            <h3>4. Key Compliance Highlights with Digital Data Protection Act, 2023</h3>
                            <p>Consent Management: The app follows granular consent requirements, ensuring users can
                                choose what data they wish to share and for what purpose.</p>
                            <p>Data Minimization: Only necessary data is collected, and permissions are requested only when
                                essential to app functionality.</p>
                            <p>User Rights: The app fully respects and implements the rights granted to users under the
                                DDPA, such as the Right to Access, Right to Erasure, and Right to Data Portability.</p>
                            <p>Security Measures: Robust encryption and security protocols are in place to safeguard user
                                data, with a clear data breach notification policy.</p>
                            <h3>5. Conclusion</h3>
                            <p>The Desh Ka Prakriti Prikshan App ensures compliance with the Digital Data Protection Act,
                                2023, through a transparent privacy policy and user-friendly permission management. The app
                                prioritizes user control, security, and responsible data handling while providing valuable health-
                                care awareness insights and features.</p>
                            <h3>6. Revisions to This Notice</h3>
                            <p>We may update this notice to reflect changes in our privacy practices. Any updates will be made
                                effective for all medical information we maintain, and we will notify you of significant changes.</p>
                            <h3>7. Complaints</h3>
                            <p>If you believe your privacy rights have been violated, you may file a complaint with us at
                                info@prakrutiparikshan-ncism.com.</p>
                            <p>We are committed to resolving any complaints and ensuring the protection of your privacy.</p>
                            <h3>8. Other Uses of Health Information</h3>
                            <p>Any other uses or disclosures not covered by this notice or other laws will require your
                                written permission. You may revoke your permission at any time, in writing, but this will not
                                affect any disclosures already made with your prior permission.</p>
                            <h3>9. Contact Us</h3>
                            <p>If you have any questions about this Privacy Policy, you can contact us:</p>
                            <p>By email: info@prakrutiparikshan-ncism.com</p>
                            <p>By visiting this page on our website: prakrutiparikshan-ncism.com</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">

                    </div>
                </div>
            </section>
        </>
    );
}

export default PrivacyPolicy;
