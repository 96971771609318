import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

function Sience() {
    return (
        <>
            <section className='home-banner'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <img src="./assets/image1.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-pending si-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className='title'>Science and Research Behind Prakriti</h2>
                        </div>
                    </div>
                    <div className="row si-2-2">
                        <div className="col-md-9">
                            <div className='box'>
                                <a href="./assets/pdf/PrakritiPoster_1.pdf" target='_blank'>
                                    <FontAwesomeIcon icon={faFilePdf} /> What is PRAKRITI ?
                                </a>
                                <a href="./assets/pdf/PrakritiPoster_2.pdf" target='_blank'>
                                    <FontAwesomeIcon icon={faFilePdf} /> Does Prakriti have a genetic and molecular basis?
                                </a>
                                <a href="./assets/pdf/Ayurgenomics Concept Poster Edited NCISM.pdf" target='_blank'>
                                    <FontAwesomeIcon icon={faFilePdf} /> Ayurgenomics Approach for Deciphering the
                                    System Biological and Molecular Basis of Prakriti
                                </a>
                                <a href="./assets/pdf/Success Story Poster.pdf" target='_blank'>
                                    <FontAwesomeIcon icon={faFilePdf} /> Ayurgenomics: An innovative framework for
                                    evidence based integrative Medicine
                                </a>
                                <a href="./assets/pdf/Exome Poster.pdf" target='_blank'>
                                    <FontAwesomeIcon icon={faFilePdf} /> Differential Disease Risk amongst Healthy Individuals of Extreme
                                    Prakriti types revealed Through Exome Sequencing
                                </a>
                                <a href="./assets/pdf/Hutt Poster.pdf" target='_blank'>
                                    <FontAwesomeIcon icon={faFilePdf} /> Differential Physiological Response to Stress amongst Different
                                    Prakriti Individuals of Extreme Types
                                </a>
                                <a href="./assets/pdf/Microbiome Poster.pdf" target='_blank'>
                                    <FontAwesomeIcon icon={faFilePdf} />The First Report on Gut Microbiome Differences Amongst Healthy
                                    Individuals of Extreme Prakriti Types
                                </a>
                                <a href="./assets/pdf/Researches on Prakriti.pdf" target='_blank'>
                                    <FontAwesomeIcon icon={faFilePdf} /> Researches in Multi-omics establishing
                                    Molecular Variability underlying Prakriti
                                </a>
                                <a href="./assets/pdf/Publications1.pdf" target='_blank'>
                                    <FontAwesomeIcon icon={faFilePdf} /> Publications 1
                                </a>
                                <a href="./assets/pdf/Publications2.pdf" target='_blank'>
                                    <FontAwesomeIcon icon={faFilePdf} /> Publications 2
                                </a>
                                <a href="./assets/pdf/Publications3.pdf" target='_blank'>
                                    <FontAwesomeIcon icon={faFilePdf} /> Publications 3
                                </a>
                                <a href="./assets/pdf/Publications4.pdf" target='_blank'>
                                    <FontAwesomeIcon icon={faFilePdf} /> Publications 4
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Sience;
