import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function Volunteer() {
    return (
        <>
            <section className='home-banner'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <img src="./assets/image1.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className='section-pending vl-1'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className='title'>Welcome to the Volunteer's Corner!</h2>
                            <p>Thank you for joining the Desh ka Prakriti Parikshan initiative! As a volunteer, you are at the heart of a nationwide movement to promote health, wellness, and awareness about Ayurveda and personalized lifestyles. Your role in helping people understand their Prakriti and make positive lifestyle choices is invaluable to this mission.</p>
                            <p>Through your efforts, we’re reaching communities across India, one person at a time, and guiding them toward balanced, healthier lives. As a part of this campaign, you’re contributing to a <strong>deeper connection between traditional Ayurvedic wisdom and modern wellness practices.</strong></p>
                            <p>This corner is here to support you at every step. Here, you’ll find resources, training materials, and insights to help you carry out Prakriti assessments with confidence. Let’s work together to make a lasting impact on health and wellness, empowering individuals to embrace their unique Prakriti and lead fulfilling lives.</p>
                            <p>Welcome to the team!</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section-pending vl-2'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className='title'>Training and Resources:</h2>
                            <ul>
                                <li>
                                    <a href="">
                                        <label htmlFor="">Training videos</label>
                                    </a>
                                </li>
                                <li>
                                    <a href="./assets/pdf/Training_PPT_for_DKPP_Volunteers.pdf" target='_blank'>
                                        <label htmlFor="">Reading materials: Manual PDF, etc</label>
                                    </a>
                                </li>
                                <li>
                                    <a href="./assets/pdf/SOP - Desh ka Prakruti Parikshan.pdf" target='_blank'>
                                        <label htmlFor="">Volunteer Handbook: SOP to approach citizens, other important information. </label>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section-pending vl-3 d-none'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className='title'>Standard Operating Procedure (SOP) <br />
                                for Volunteer for initiating the Prakriti Analysis of citizen</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <h3>Self-Introduce yourself;</h3>
                            <p>When performing the Prakriti analysis of the Citizen or Person, the volunteer should introduce themselves as an Ayurvedic practitioner/instructor/student/medical officer representing the Desh Ka Prakriti Parikshan Campaign on behalf of NCISM and the Ministry of Ayush.</p>
                            <h3>Provide Information about Campaign:</h3>
                            <p>The Desh ka Prakriti Parikshan Campaign is a Nationwide campaign envisioned by Hon'ble Minister of Ayush (Independent Charge), Shri. Prataprao Jadhav ji. This Campaign was launched by Hon’ble Prime Minister of India, Shri. Narendra Modi ji, on the auspicious occasion of Ayurveda Day, celebrated on October 29, 2024.</p>
                            <p>Individuals whose Prakriti will be analysed, shall get health advice on a regular basis for promotion of positive health.</p>
                            <h3>Brief about what is Prakriti? and its importance for personalized health and wellness</h3>
                            <p>Prakriti refers to an individual's unique constitution or inherent nature, which is determined by the balance of the three doshas: Vata, Pitta, and Kapha. These doshas govern all the physical, mental, and emotional functions in the body, and their balance influences a person's health, behaviour. Prakriti helps in creating personalized diet, lifestyle, and treatment plans to remain healthy.</p>
                            <p>Each person has a distinct combination of these doshas, and understanding this constitution helps in tailoring personalized approaches to diet, lifestyle, and treatments that maintain health and prevent disease.</p>
                            <h3>Explain other benefits: Referral to a Nearby Hospital or Doctor if Needed: – </h3>
                            <p>If any health concerns come up during the assessment, you can be directed to a nearby Ayurveda hospital or doctor for guidance. So, it’s a great way to look after your health, with support if needed.</p>
                        </div>
                        <div className='col-md-6'>
                            <h3>Explain the Process of Assessment of Prakriti</h3>
                            <ol>
                                <li>Download the Prakriti Parikshan application through play store
                                    Which is completely free and Approved by Govt.
                                    (Available on IOS & Android)
                                </li>
                                <li>Then after Citizen should select Citizen tab</li>
                                <li>Log- in with your Mobile number
                                    (Mobile Number and Other information will not be share with anyone and incompliance to the Data Protection Act necessary work will be done)
                                </li>
                                <li>Verify with an OTP</li>
                                <li>Citizen should provide basic details and give your consent to participate.
                                    Explain to Citizen that I’ll use my app to help you complete the Prakriti assessment, which includes examining features like your body, habits, and other health details.
                                    (Make aware that Prakriti Parikshan will be done through Mobile application Question Answer mode only, No blood sample or other tests will be done)
                                </li>
                                <li>You will also record a video pledge, saying a simple sentence, <br />
                                    (मैंने किया है संकल्प स्वास्थ्य का, जिसका है आधार आयुर्वेद का)
                                </li>
                                <li>We will take a photo of you with your Prakriti certificate.</li>
                            </ol>
                            <h3>Explain Benefits of Prakriti Analysis</h3>
                            <ul>
                                <li>By learning about your Prakriti, you will get advice for a healthier lifestyle that are specific to your body type. </li>
                                <li>Once you complete the process, you’ll receive a digital Prakriti certificate on the application. </li>
                                <li>You will get personalized lifestyle recommendations, including daily and seasonal practices to stay well round the year.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-pending si-1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className='title'>Research Articles and Publications</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-6'>
                            <h3>Genomic and Molecular corelates of Prakriti </h3>
                            <ol>
                                <li>
                                    Prasher, B., Negi, S., Aggarwal, S. et al. Whole genome expression and biochemical correlates of extreme constitutional types defined in Ayurveda. J Transl Med 6, 48 (2008).
                                    <a href='https://doi.org/10.1186/1479-5876-6-48' target='_blank'>Read more</a>
                                </li>
                                <li>
                                    Aggarwal S, Negi S, Jha P, et al. EGLN1 involvement in high-altitude adaptation revealed through genetic analysis of extreme constitution types defined in Ayurveda. Proc Natl Acad Sci U S A. 2010;107(44):18961-18966.
                                    <a href='https://doi.org/10.1073/pnas.1006108107' target='_blank'>Read more</a>
                                </li>
                                <li>
                                    Juyal RC, Negi S, Wakhode P, Bhat S, Bhat B, Thelma BK (2012) Potential of Ayurgenomics Approach in Complex Trait Research: Leads from a Pilot Study on Rheumatoid Arthritis. PLoS ONE 7(9): e45752.
                                    <a href="https://doi.org/10.1371/journal.pone.0045752">Read more</a>
                                </li>
                                <li>
                                    Bhalerao, S., Deshpande, T., & Thatte, U. Prakriti (Ayurvedic concept of constitution) and variations in platelet aggregation. BMC complementary and alternative medicine, 12, 248. (2012).
                                    <a href="https://doi.org/10.1186/1472-6882-12-248 " target='_blank'>Read more</a>
                                </li>
                                <li>
                                    Aggarwal, S., Gheware, A., Agrawal, A. et al. Combined genetic effects of EGLN1 and VWF modulate thrombotic outcome in hypoxia revealed by Ayurgenomics approach. J Transl Med 13, 184 (2015).
                                    <a href="https://doi.org/10.1186/s12967-015-0542-9 " target='_blank'>Read more</a>
                                </li>
                                <li>
                                    Rotti, H., Mallya, S., Kabekkodu, S. P., Chakrabarty, S., Bhale, S., Bharadwaj, R., Bhat, B. K., Dedge, A. P., Dhumal, V. R., Gangadharan, G. G., Gopinath, P. M., Govindaraj, P., Joshi, K. S., Kondaiah, P., Nair, S., Nair, S. N., Nayak, J., Prasanna, B. V., Shintre, P., Sule, M., … Satyamoorthy, K. DNA methylation analysis of phenotype specific stratified Indian population. Journal of translational medicine, 13, 151. (2015).
                                    <a href="https://doi.org/10.1186/s12967-015-0506-0 " target='_blank'>Read more</a>
                                </li>
                                <li>
                                    Govindaraj, P., Nizamuddin, S., Sharath, A. et al. Genome-wide analysis correlates Ayurveda Prakriti. Sci Rep 5, 15786 (2015).
                                    <a href="https://doi.org/10.1038/srep15786" target='_blank'>Read more</a>
                                </li>
                                <li>
                                    Abbas, T., Chaturvedi, G., Prakrithi, P., Pathak, A. K., Kutum, R., Dakle, P., Narang, A., Manchanda, V., Patil, R., Aggarwal, D., Girase, B., Srivastava, A., Kapoor, M., Gupta, I., Pandey, R., Juvekar, S., Dash, D., Mukerji, M., & Prasher, B. Whole Exome Sequencing in Healthy Individuals of Extreme Constitution Types Reveals Differential Disease Risk: A Novel Approach towards Predictive Medicine. Journal of personalized medicine, 12(3), 489. (2022).
                                    <a href="https://doi.org/10.3390/jpm12030489" target='_blank'>Read more</a>
                                </li>
                            </ol>
                            <h3>Gut Microbiome differences underlying prakriti</h3>
                            <ol>
                                <li>
                                    Chauhan, N. S., Pandey, R., Mondal, A. K., Gupta, S., Verma, M. K., Jain, S., Ahmed, V., Patil, R., Agarwal, D., Girase, B., Shrivastava, A., Mobeen, F., Sharma, V., Srivastava, T. P., Juvekar, S. K., Prasher, B., Mukerji, M., & Dash, D. Western Indian Rural Gut Microbial Diversity in Extreme PrakritiEndo-Phenotypes Reveals Signature Microbes. Frontiers in microbiology, 9, 118. (2018)
                                    <a href="https://doi.org/10.3389/fmicb.2018.00118 " target='_blank'>Read more</a>
                                </li>
                            </ol>
                        </div>
                        <div className='col-md-6'>
                            <h3>Cellular level differences amongst Prakriti</h3>
                            <ol>
                                <li>
                                    Chakraborty, S., Singhmar, S., Singh, D., Maulik, M., Patil, R., Agrawal, S. K., Mishra, A., Ghazi, M., Vats, A., Natarajan, V. T., Juvekar, S., Prasher, B., & Mukerji, M. Baseline cell proliferation rates and response to UV differ in lymphoblastoid cell lines derived from healthy individuals of extreme constitution types. Cell cycle (Georgetown, Tex.), 20(9), 903–913. (2021)
                                    <a href="https://doi.org/10.1080/15384101.2021.1909884 " target='_blank'>Read more</a>
                                </li>
                            </ol>
                            <h3>Physiological differences in response to stress</h3>
                            <ol>
                                <li>
                                    Rani, R., Rengarajan, P., Sethi, T., Khuntia, B. K., Kumar, A., Punera, D. S., Singh, D., Girase, B., Shrivastava, A., Juvekar, S. K., Pesala, B., Mukerji, M., Deepak, K. K., & Prasher, B. Heart rate variability during head-up tilt shows inter-individual differences among healthy individuals of extreme Prakriti types. Physiological reports, 10(17), e15435. (2022).
                                    <a href="https://doi.org/10.14814/phy2.15435 " target='_blank'>Read more</a>
                                </li>
                            </ol>
                            <h3>Review articles as reading resources</h3>
                            <ol>
                                <li>
                                    Prasher, B., Gibson, G., & Mukerji, M. Genomic insights into ayurvedic and western approaches to personalized medicine. Journal of genetics, 95(1), 209–228 (2016).
                                    <a href="https://doi.org/10.1007/s12041-015-0607-9 " target='_blank'>Read more</a>
                                </li>
                                <li>
                                    Prasher, B., Varma, B., Kumar, A., Khuntia, B. K., Pandey, R., Narang, A., Tiwari, P., Kutum, R., Guin, D., Kukreti, R., Dash, D., TRISUTRA Ayurgenomics Consortium, & Mukerji, M. Ayurgenomics for stratified medicine: TRISUTRA consortium initiative across ethnically and geographically diverse Indian populations. Journal of ethnopharmacology, 197, 274–293. (2017)
                                    <a href="https://doi.org/10.1016/j.jep.2016.07.063 " target='_blank'>Read more</a>
                                </li>
                                <li>
                                    Sethi, T.P., Prasher, B#. and Mukerji, M., 2011. Ayurgenomics: a new way of threading molecular variability for stratified medicine. ACS Chemical . Biology  2011, 6, 9, 875–880
                                    <a href="https://doi.org/10.1021/cb2003016" target='_blank'>Read more</a>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Volunteer;
