import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/home/Home';
import Abhiyaan from './pages/abhiyaan/Abhiyaan';
import Faq from './pages/faqs/FAQ';
import Volunteer from './pages/volunteer/Volunteer';
import Sience from './pages/sience/Sience';
import Contact from './pages/contact/Contact';
import PrivacyPolicy from './pages/contact/PrivacyPolicy';
import TermsCondition from './pages/contact/TermsCondition';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
    return (
        <BrowserRouter>
            <Header />
            <div className='main-page'>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/join-the-abhiyaan" element={<Abhiyaan />} />
                    <Route path="/volunteers-corner" element={<Volunteer />} />
                    <Route path="/faqs" element={<Faq />} />
                    <Route path="/sience-corner" element={<Sience />} />
                    <Route path="/contact-us" element={<Contact />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-condition" element={<TermsCondition />} />
                </Routes>
            </div>
            <Footer />
        </BrowserRouter>

    );
}

export default App;
